import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import '../styles/global.css'

export const FeatureTemplate = ({
  title,
  subtitle,
  image,
  content,
  contentComponent,
  featureContent,
  featureContentList,
}) => {
  const FeatureContent = contentComponent || Content

  return (
    <Fragment>
      <Helmet titleTemplate={ `${title} - %s` } />
      <Jumbotron
        fluid
        style={{
          background: `transparent url(${image}) no-repeat center center / cover`,
          height: '96vh',
          minHeight: '640px',
        }}
        >
          <div className="jumbotron__title">
            <div className="container">
              <h2>{ title }</h2>
              {subtitle && <p className="subtitle">{ subtitle }</p>}
            </div>
          </div>
        </Jumbotron>
      <Container>
        <section>
          <FeatureContent className="content" content={ content } />
        </section>
        {
          featureContent.map((section, index) => {
            return (
              <section key={ index } className="section section--hrule">
                { section.useHeading && <h3>{ section.heading }</h3>}
                { !!section.image && 
                  <Jumbotron
                    className="mt-3"
                    style={{
                      backgroundImage: `url(${section.image})`,
                    }}
                  ></Jumbotron>
                }
                { !!section.body && <FeatureContent content={featureContentList[index]} />}
              </section>
            )
          })
        }
      </Container>
    </Fragment>
  )
}

FeatureTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  subtitle: PropTypes.string,
  image: PropTypes.string,
}

const Feature = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FeatureTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        image={post.frontmatter.image}
        content={post.html}
        featureContent={post.frontmatter.content}
        featureContentList={post.fields.contentList}
      />
    </Layout>
  )
}

Feature.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Feature

export const featureQuery = graphql`
  query Feature($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image
        content {
          heading
          useHeading
          image
          body
        }
      }
      fields {
        contentList
      }
    }
  }
`
